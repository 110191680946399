import moment from "moment";
import Icon from "../common/icon";
import { useState, useEffect } from "react";
import IconButton from "../common/iconButton";
import { capitalize } from "../../utils/stringUtils";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { ButtonGroup, Dropdown, Button, Row, Col } from "react-bootstrap";
import { getRangeIconName, getRangeIconColor } from "../../utils/taskUtils";
import { parseDurationToMilliseconds } from "../../utils/stringUtils";

const AlertTypes = [
  {
    title: "Any",
    value: "any"
  },
  {
    title: "Alert",
    value: "alert"
  }, {
    title: "Feedback",
    value: "feedback"
  }, {
    title: "Alert response",
    value: "alert response"
  }, {
    title: "Trigger",
    value: "trigger"
  }];

const AlertsTable = ({ alerts, className }) => {  
  const [items, setItems] = useState(alerts);
  const [showFilters, setShowFilters] = useState(false);
  const [sortData, setSortData] = useState({});
  const [searchData, setSearchData] = useState({
    title: "",
    type: {
      title: "",
      value: "",
    },
    timeFrom: "",
    timeTo: ""
  });

  const handleSort = (key) => {
    setSortData({ key: key, order: (sortData?.order || 1) * -1 });
  }

  const handleSearch = (key, value) => {
    setSearchData({ ...searchData, [key]: value });
  }

  const sort = (items) => {
    return [...items].sort((left, right) => left[sortData.key] < right[sortData.key] ? 1 * sortData.order : -1 * sortData.order);
  }

  const search = (items) => {
    return [...items].filter((item) => {
      return (item.title.toLowerCase().includes(searchData.title.toLowerCase())) 
      && (item.type === searchData.type.value || searchData.type.value === "any" || searchData.type.value === "")
      && isInRange(item.time, searchData.timeFrom, searchData.timeTo);
    });
  }

  const isInRange = (time, timeFromStr, timeToStr) => {
    if (!timeFromStr && !timeToStr)
      return true;

    if (timeFromStr && !timeToStr)
      return time >= parseDurationToMilliseconds(timeFromStr);

    if (!timeFromStr && timeToStr)
      return time <= parseDurationToMilliseconds(timeToStr);

    return time >= parseDurationToMilliseconds(timeFromStr) && time <= parseDurationToMilliseconds(timeToStr);
  }

  useEffect(() => {
    setItems(sort(search(alerts)));
  }, [alerts]);

  useEffect(() => {
    setItems(sort(items));
  }, [sortData]);

  useEffect(() => {
    setItems(search(alerts));
  }, [searchData]);

  return (
    <div className={className}>
      <div className="d-flex justify-content-space-between align-items-center">
        <h2 className="m-0 text-medium mx-2"> Alerts report </h2>
        <IconButton icon="sliders" onClick={() => setShowFilters(!showFilters)} />
      </div>

      {showFilters && (
        <Row style={{ rowGap: "16px" }} className="mt-4 mb-3">
          <Col sm={4}>
            <div className="labeled-input fluid">
              <input
                className="input"
                type="text"
                placeholder=" "
                value={searchData.title}
                onChange={(event) => handleSearch("title", event.target.value)}
              />
              <label> Title </label>
            </div>
          </Col>

          <Col sm={4}>
            <Dropdown as={ButtonGroup} className="d-grid custom-dropdown fluid">
              <Dropdown.Toggle as={Button} className="text-left" style={{}}>
                <span> {searchData.type.title || "Type"} </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100 text-left" variant="dark">
                {AlertTypes.map((item) => (
                  <Dropdown.Item
                    className={"w-100"}
                    key={item.value}
                    eventKey={item.value}
                    active={searchData.type.value === item.value}
                    onClick={() => handleSearch("type", item)}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col sm={4}>
            <Row style={{ rowGap: "16px" }}>
              <Col sm={6}>
                <div className="labeled-input fluid">
                  <input
                    className="input"
                    type="text"
                    placeholder="HH:MM:SS"
                    value={searchData.timeFrom}
                    onChange={(event) => handleSearch("timeFrom", event.target.value)}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="labeled-input fluid">
                  <input
                    className="input"
                    type="text"
                    placeholder="HH:MM:SS"
                    value={searchData.timeTo}
                    onChange={(event) => handleSearch("timeTo", event.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <table className="table sortable text-white mt-3">
        <thead>
          <tr>
            {[
              { title: "Title", key: "title" },
              { title: "Type", key: "type" },
              { title: "Time", key: "time" }
            ].map(({ title, key }) => (
              <th key={key} onClick={() => handleSort(key)}>
                {sortData.key === key ? sortData.order === 1 ? <FaArrowDown /> : <FaArrowUp /> : ""} {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && (
            <tr>
              <td colSpan="3"> No alerts. </td>
            </tr>
          )}
          {items.map((alert, index) => (
            <tr key={index}>
              <td>
                <Icon
                  chartIcon={true}
                  name={`${getRangeIconName(
                    alert.type === "trigger"
                      ? "trigger"
                      : alert.subject || alert.title
                  )}_${getRangeIconColor(
                    alert.type === "alert"
                      ? "alertSend"
                      : alert.type === "feedback"
                        ? "feedback"
                        : alert.type === "trigger"
                          ? "trigger"
                          : alert.title
                  )}`}
                />
                {alert.title} </td>
              <td> {capitalize(alert.type) || "-"} </td>
              <td> {moment.utc(alert.time).format("HH:mm:ss")} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AlertsTable;
import { TaskState } from "./constants";
import { TOKEN_KEY } from "../store/constants";

export const getDownloadLink = (taskId, dataType, isReduced) =>
  `${process.env.REACT_APP_SERVER_URL}/task/data/download/${taskId}/${dataType}/${isReduced}/${localStorage.getItem(TOKEN_KEY)}`;

export const cleanChartData = (chartData) =>
  chartData
    .filter((item) => item.time !== undefined)
    .sort((itemLeft, itemRight) => itemLeft.time > itemRight.time ? 1 : -1);

export const filterUniquePoints = (chartData, key) => {
  const seenKeyValues = new Set();

  const filteredData = chartData.filter((point) => {
    if (!seenKeyValues.has(point[key])) {
      seenKeyValues.add(point[key]);
      return true;
    }

    return false;
  });

  console.info(`Filtered joint offline + online data and dropped ${chartData.length - filteredData.length} points.`)
  return filteredData;
};

export const isTaskStreaming = (taskState) =>
  [TaskState.RUNNING, TaskState.PAUSED].includes(taskState);

export const getRangeIconName = (alertType) => {
  alertType = alertType.toLowerCase();

  switch (alertType) {
    case "tired":
      return "battery";
    case "shaking":
      return "shaking";
    case "sleepy":
      return "sleep";
    default:
      return "trigger";
  }
};

export const getRangeIconColor = (alertType) => {
  alertType = alertType.toLowerCase();

  switch (alertType) {
    case "alertsend":
      return "blue";
    case "yes":
      return "green";
    case "no":
      return "red";
    case "feedback":
      return "yellow";
    case "trigger":
      return "white";
    default:
      return ""
  }
};
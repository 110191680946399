import React, { useState } from 'react';
import { TRIGGERS } from '../../store/constants';
import { Dropdown, ButtonGroup, Button } from 'react-bootstrap'; // Import necessary Bootstrap components

const TriggerDropdown = ({ sendTrigger }) => {
  const [selectedTrigger, setSelectedTrigger] = useState('');
  const [customTrigger, setCustomTrigger] = useState('');

  const handleTriggerClick = (trigger) => {
    if (trigger !== 'CUSTOM') {
      sendTrigger(trigger);
      setSelectedTrigger('');
    } else {
      setSelectedTrigger('CUSTOM');
    }
  };

  const handleCustomTriggerChange = (event) => {
    setCustomTrigger(event.target.value);
  };

  const handleSendCustomTrigger = () => {
    if (customTrigger.trim()) {
      sendTrigger(customTrigger);
      setCustomTrigger('');
      setSelectedTrigger('');
    }
  };

  return (
    <div className="mt-3">
      <Dropdown as={ButtonGroup} className="d-grid button custom-dropdown fluid">
        <Dropdown.Toggle as={Button} className="text-left">
          {selectedTrigger || 'Select a Trigger'}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 text-left" variant="dark">
          {TRIGGERS.map((trigger) => (
            <Dropdown.Item
              key={trigger}
              eventKey={trigger}
              active={selectedTrigger === trigger}
              onClick={() => handleTriggerClick(trigger)}
            >
              {trigger}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {selectedTrigger === 'CUSTOM' && (
        <div className="p-3">
          <input
            type="text"
            value={customTrigger}
            onChange={handleCustomTriggerChange}
            placeholder="Enter custom trigger title"
            className="form-control"
          />
          <button
            onClick={handleSendCustomTrigger}
            className="btn btn-primary mt-2 w-100"
          >
            Send Custom Trigger
          </button>
        </div>
      )}
    </div>
  );
};

export default TriggerDropdown;
